import React, { useState } from 'react';
import Room from '../components/RoomGaleria';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Blur from '../components/Blur';

import { useBreakpoint, BreakpointProvider } from 'gatsby-plugin-breakpoints';
import Audio from '../components/Audio';

import Overlay from '../components/LoaderOverlay';

import * as THREE from 'three';
import Arrow from '../components/Arrow';
import { Seo } from "../components/SEO"

import commentsData from '../other/commentsOrtopediaData';

const IndexPage = () => {
  const page = "Ortopedia - Galería";
  const [welcome, setWelcome] = useState(true);
  const [audio, setAudio] = useState(false);
  const [listActive, setListActive] = useState(false);

  const breakpoints = useBreakpoint();
  const [loading, setLoading] = useState(true);

  const [giro, setGiro] = useState(false);

  const mainProduct = {
    pretitle: "Plantillas",
    title: "Bauerfeind",
    link: "https://ortopedia.kaldevi.com",
    titlePos: [400, 70, 0],
  }

  return (
    <div>
      <Overlay visible={loading}/>
      {!welcome && <Header setAudio={setAudio} audio={audio} page={page} setListActive={setListActive} listActive={listActive}/>}
      <Footer giro={giro} setGiro={setGiro}/>
      <Blur page={page} setBlur={setWelcome} blur={welcome}>
        <Room 
          giro={giro}
          setGiro={setGiro}
          breakpoints={breakpoints} 
          listActive={listActive} 
          setLoading={setLoading} 
          welcome={welcome}
          panorama={'/panos/pano_art1.webp'}
          panoramaB={'/panos/pano_art1_b.webp'}
          detectorPosition={[85, 10, -12]}
          detectorRotation={[0, THREE.MathUtils.degToRad(-90), THREE.MathUtils.degToRad(-15)]}
          comments={commentsData}
          commentColor={"black"}
          mainProduct={mainProduct}>
            <Arrow breakpoints={breakpoints} setLoading={setLoading} position={[35, 0, -20]} dir={"Ortopedia\nZona Técnica"} textpos={"right"} rotation={[0, THREE.MathUtils.degToRad(-90), 0]} scale={4} to={"/ortopedia-tecnica"} giro={giro}/>
            <Arrow breakpoints={breakpoints} setLoading={setLoading} position={[35, -7, -16]} dir={"Bienestar\nGaleria"} textpos={"left"} rotation={[0, THREE.MathUtils.degToRad(-90), -Math.PI/2]} scale={4} to={"/bienestar-galeria"} giro={giro}/>
            <Arrow breakpoints={breakpoints} setLoading={setLoading} position={[-15, -7, -40]} dir={"Entrada"} textpos={"left"} rotation={[0, THREE.MathUtils.degToRad(45), Math.PI]} scale={5} to={"/"} giro={giro}/>
          </Room>
      </Blur>

      <Audio audio={audio} setAudio={setAudio} isActive={welcome}/>
      
    </div>
  )
}

export default IndexPage

export function Head() {
  return(
    <Seo />
  )
}
